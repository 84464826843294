.border-radius-8 {
    border-radius: 8px;
}

.border-radius-6 {
    border-radius: 6px;
}

.border-radius-4 {
    border-radius: 4px;
}

.border-radius-3 {
    border-radius: 3px;
}

.pointer-cursor {
    cursor: pointer;
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-800 {
    font-weight: 800;
}

.right-float {
    float: right;
}

.left-float {
    float: left;
}

.height-fill {
    height: -webkit-fill-available;
    height: -moz-available;
}

.width-fill {
    width: -webkit-fill-available;
    width: -moz-available;
}

.width-fit {
    width: fit-content;
}

.font-size-10 {
    font-size: 10px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-14 {
    font-size: 14px;
}

.display-none {
    display: none;
}

.line-height-24 {
    line-height: 24px;
}

.common-btn {
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 0.8rem 2rem 0.8rem 2rem;
    width: 100%;
}

.close-icon {
    height: 1.875rem;
    width: 1.875rem;
}

.common-btn:hover {
    background-color: var(--normal-btn-hover);
}

.highlight-right-border {
    border-right: 2px solid var(--selected-contact-border-color);
}

.highlight-right-border-none {
    border-right: none;
}

input:focus {
    outline-width: 0;
}

/* CSS for all drop down items */
.dropdown-content {
    flex-direction: column;
    position: absolute;
    background-color: var(--background-container);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: 1px solid #000000;
    border-radius: 3px;
    margin-top: 0.1rem;
    margin-left: -9rem;
    display: none;
}

.dropdown-content-top-align {
    margin-top: -6.9rem !important;
}

.dropdown-content div {
    color: var(--text-primary-color);
    padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.dropdown-content div:hover {
    background-color: var(--normal-btn);
}

.line-separator {
    height: 0.1rem;
}

.menu-icon-container {
    padding: 0.75rem 0.7rem 0.5rem 2rem;
}

.profile-configuration-box {
    background: var(--info-box-background-color);
    color: var(--info-box-text-color);
    font-size: 14px;
}

.profile-configuration-box-border {
    border: 1px solid var(--info-box-text-color);
}

.height-inherit {
    height: inherit;
}

/* CSS to rotate the spinner svg image */
@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

/* CSS to set scrollbar color and size */
::-webkit-scrollbar-track {
    padding: 2px 0;
    background-color: var(--scrollbar-background-color);
    border-radius: 15px;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: var(--scrollbar-scroller-color);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--scrollbar-scroller-color);
}

/* =================== Mobile Responsive CSS =================== */
@media only screen and (max-width: 800px) {
    .menu-icon-container img {
        width: 1.5rem !important;
    }

    .menu-icon-container {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        padding-left: 1rem;
    }

    .mobile-profile-icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        word-break: break-word;
        font-size: 0.56rem;
        hyphens: auto;
    }

    .profile-configuration-box {
        font-size: 12px;
    }

    .blur-background {
        filter: blur(4px);
        background-color: rgba(0, 0, 0, 0.4);
        backdrop-filter: brightness(0.3);
    }

}

@media only screen and (min-width: 800px) {
    .mobile-profile-icon {
        display: none !important;
    }
}
